@import url("https://fonts.googleapis.com/css2?family=Arima&family=Gentium+Book+Plus:ital,wght@1,400;1,700&family=Playfair+Display:ital,wght@0,400;1,600&family=Syne+Mono&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
